import React, { useEffect, useState } from "react";
import { graphql } from 'gatsby';

import Layout from "../components/layout";
import SEO from "../components/seo";

import {cloneDeep, flatten, filter} from 'lodash';

const Privacy = props => {

    const language_id = 2;
    const privacy = filter(props.data.allPrivacy.nodes, function(privacy){ return privacy.language_id == language_id})[0];


    return (
        <Layout pathContext={props.pathContext}>
            <SEO title="Datenschutz" />

                {privacy && (
                    <section className="privacy_section">
                        <div className="privacy_wrapper main_content_wrapper">
                            <h1>{privacy.title}</h1>

                            <div
                                className="privacy_text_wrapper"
                                dangerouslySetInnerHTML={{
                                    __html: privacy.description
                                }}
                            ></div>

                        </div>
                    </section>

                )}

        </Layout>
    );
};

export default Privacy


export const query = graphql`
    query Privacy {
        allPrivacy {
            nodes {
                language_id
                title
                description
            }
        }
    }
`
  